import React, { useEffect, useState } from 'react'
import { GridColDef } from '@mui/x-data-grid'
import { GridItem } from '../gridItems'
import { ISubmission } from '../../../../domain/interfaces/ISubmission'
import { dateToMMMDYYYY } from '../../../../core/core'
import { IForm } from '../../../../domain/interfaces/IForm'
import {
  commonComponents,
  findComponent,
  isImageFile,
} from '../../../../core/utils/formio-formatters/FormIOFormatter'
import { Link, Typography } from '@mui/material'
import { AppColors } from '../../../Theme'
import { getToken, httpGet } from '../../../../data/data'
import { useOrganizationProvider } from '../../../../providers/OrganizationProvider'
import { getCurrentOrg } from '../../../../domain/domain'
import _ from 'lodash'

function isString(value: any) {
  return (
    typeof value === 'string' ||
    Object.prototype.toString.call(value) === '[object String]'
  )
}

const datetimeRegex = /\d{4}-\d{2}-\d{2}(T.*)?/

const changeHeaderLabel = [
  '{{data.dataSource.level1Name}}',
  '{{data.dataSource.projectName}}',
]

const checkHeader = (header: string, key: string, type: string) => {
  if (changeHeaderLabel.includes(header)) return `${key}`
  return header
}

export const getDynamicHeader = (
  normalizedDynamicHeaders: any,
  hasCreatedAtField: boolean,
): GridColDef[] => {
  if (!normalizedDynamicHeaders) return []
  const { formData } = useOrganizationProvider()
  const getName = (id: string) => {
    return formData?.find((x) => x.id === id)?.name ?? '-'

  }
  const removeDuplicatesLodash =  _.uniqBy(normalizedDynamicHeaders, 'key')
  
  const headers: GridColDef[] = removeDuplicatesLodash.map((fh: any) => {
    const headerName = fh ? fh.name : 'No Name'
    const headerKey = fh ? fh.key : 'No Key'
    const headerType = fh ? fh.type : 'No Type'
    // headerType += fh.isFromCa
    //   ? '-CA'
    //   : fh.isFromScheduler
    //     ? '-S'
    //     : fh.isCommon
    //       ? '-C'
    //       : '-D'
    return {
      field: fh.key,
      flex: 1,
      minWidth: fh.type === 'signature' ? 200 : 250,

      renderHeader: () => (
        <GridItem
          data-testid='DynamicHeader'
          text={checkHeader(headerName, headerKey, headerType)}
        />
      ),
      renderCell: ({ row }: any) => {
        try {
          if (headerType === 'file') {
            return renderFiles(row[fh.key], row.id)
          }
          // if (fh.url) {
          //   // load from BE
          //   return <GridItemLoader url={row[fh.key]} />
          // }
          return <GridItem text={row[fh.key]} />
        } catch (err) {
          return <GridItem text={'**'} />
        }
      },
    }
  })

  // NEED TO HAVE createdAt field for MUI dat grid to sort, so if it is not selected as dynamic field add it and hide it so default can be createdAt
  if (!hasCreatedAtField) {
    headers.push({
      field: 'createdAt',
      sortable: false,
      renderHeader: () => <GridItem text='' />,
      width: 0,
      renderCell: ({ row }) => <GridItem text='' />,
    })
  }
  return headers
}

const renderFiles = (fileData: any, id: string) => {
  const token = getToken() ?? ''
  const orgId = getCurrentOrg()?.id ?? ''
  let files = []
  //only support objects
  if (typeof fileData != 'object') return <GridItem text={''} />

  // just an object and has a url (Single upload component)
  if (!Array.isArray(fileData) && fileData.url) {
    files = [fileData]
  } else {
    files = fileData
  }
  if (files.length == 0) {
    return <GridItem text={''} />
  }
  const displayFiles = []
  const loopCount = files.length <= 2 ? files.length : 2
  for (let i = 0; i < loopCount; i++) {
    const file = files[i]

    if (!file && !file.url) continue
    if (isImageFile(file.url)) {
      displayFiles.push(
        <img
          key={`${file.url}-${id}-${i}`}
          src={file.url + `&token=${token}&orgId=${orgId}`}
          height='50'
        />,
      )
    } else {
      displayFiles.push(
        // <Typography noWrap sx={{ lineHeight: '24px' }}>
        <Link
          key={`${file.url}-${id}-${i}`}
          noWrap
          href={file.url}
          target='_blank'
          rel='noreferrer'
          sx={{
            lineHeight: '44px',
            textDecoration: 'none',
            color: AppColors.primary600,
          }}
        >
          {file.originalName ?? 'Link'}
          {/* {files.length > 2 || (i != 1 && files.length && ',')} */}
        </Link>,
        // </Typography>,
      )
    }
  }
  if (files.length > 2) {
    displayFiles.push(
      <GridItem
        key={`more-${id}`}
        text={`${files.length - 2} more...`}
        sx={{ lineHeight: '44px', color: AppColors.neutral800 }}
      />,
    )
  }
  return <div style={{ display: 'flex', gap: '6px' }}>{displayFiles}</div>
}

const GridItemLoader = ({ url }: { url: string }) => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | undefined>(undefined)
  const [value, setValue] = useState('')
  const loadData = async () => {
    try {
      setError(undefined)
      setLoading(true)
      const res = await httpGet(url, true)
      setLoading(false)
      if (res.isLeft()) {
        setError('Error loading')
        return
      }
      if (res.right) {
        const x: any = res.right
        setValue(x.name ?? '')
        return
      }
      setValue('*')
    } catch (err) {
      setValue('*')
    }
  }
  useEffect(() => {
    loadData()
  }, [])

  if (loading) return <GridItem text='Loading...' />
  if (error) return <GridItem text={error} />
  return <GridItem text={value} />
}

type IdValue = string | { id: string }

function getId(value: IdValue): string {
  if (typeof value === 'object' && value !== null && 'id' in value) {
    return value.id
  }
  return value as string
}

const getSubmissionValue = (fh: any, s: any) => {
  if (fh.isFromCa) {
    // here s.data could have correctiveAction or correctiveActionGeneral, so we need to check for both and avoid error as s.data?.correctiveAction or s.data?.correctiveActionGeneral might be undefined and try to acces will throw error
    let value = '-'
    if (s.data?.correctiveAction) {
      value = s.data?.correctiveAction[fh.key] ?? '-'
    }
    if (s.data?.correctiveActionGeneral) {
      value = s.data?.correctiveActionGeneral[fh.key] ?? '-'
    }
    return value
  }
  if (fh.isFromScheduler) {
    if (fh.key == 'nameTeamProject') {
      const length = s.data?.scheduler[fh.key].length ?? 0
      return length + ' selected'
    }
    if (fh.key == 'invitees') {
      const length = s.data?.scheduler[fh.key].length ?? 0
      return length + ' invited'
    }

    return s.data?.scheduler[fh.key] ?? undefined
  }
  if (fh.isCommon) {
    return s[fh.key] ?? undefined
  }
  return s.data?.[fh.key] ?? undefined
}

export const normalizeDynamicHeadersRows = (
  submissions: ISubmission[],
  form: IForm,
  normalizedDynamicHeaders: any,
) => {
  const { formData } = useOrganizationProvider()
  const getName = (id: string) => {
    return formData?.find((x) => x.id === id)?.name ?? '-'
  }
  return submissions?.map((s: any) => {
    const row = normalizedDynamicHeaders?.reduce(
      (accum: any, fh: any) => {
        try {
          // Check if it is Common/Shard submission data

          // get component for each header from the form
          const component = findComponent(
            [...form.components!, ...commonComponents],
            fh.key,
            false,
            false,
            0,
          )

          const submissionValue = getSubmissionValue(fh, s)
          // will not add the column if the component is not found
          // this could cause issues right now.
          if (!component) return accum
          const { type, data, idPath } = component

          if (type === 'select') {
            // get a value from the result of API generated dropdown
            // need to check this. look at other options.
            // requires formio change => save as object
            // Value Property set blank...

            if (fh.key === 'level1') {
              accum[fh.key] = s.level1Name
              return accum
            }
            if (fh.key === 'project') {
              accum[fh.key] = s.projectName
              return accum
            }

            if (fh.isCommon) {
              accum[fh.key] = submissionValue
              return accum
            }

            // way to load data
            if (fh.loadData) {
              const id = getId(submissionValue)
              const name = getName(id)
              accum[fh.key] = name
              return accum
            }
            if (idPath && s.data?.[fh.key] && s.data?.[fh.key][idPath]) {
              accum[fh.key] = s.data?.[fh.key][idPath] ?? '-'
              return accum
            }
            // get a value from the result of a static generated dropdown

            if (data.values) {
              const selectValue = submissionValue ?? '-'
              accum[fh.key] = getSelectLabel(data.values, selectValue)
            } else {
              //
              accum[fh.key] = isString(submissionValue) ? submissionValue : '-'
            }
            return accum
          }

          if (type === 'checkbox') {
            accum[fh.key] = submissionValue ? 'checked' : 'not checked'
            return accum
          }

          if (type === 'selectboxes') {
            const trueKeys = getTrueKeys(submissionValue)
            accum[fh.key] = trueKeys ? trueKeys : '-'
            return accum
          }
          if (type === 'radio' && component.values) {
            const selectValue = submissionValue ?? '-'
            accum[fh.key] = getSelectLabel(component.values, selectValue)
            return accum
          }

          //temp fix for non string values
          if (typeof submissionValue === 'object') {
            if (Array.isArray(submissionValue)) {
              accum[fh.key] = submissionValue
              return accum
            }
            return accum
          }

          const isValidDatetime = datetimeRegex.test(submissionValue)
          if (isValidDatetime) {
            accum[fh.key] = dateToMMMDYYYY(submissionValue)
          } else {
            // match the key of the form header to the submission data
            accum[fh.key] = submissionValue
          }
          return accum
        } catch (e) {
          console.log({ e })
          accum[fh.key] = '*'
          return accum
        }
      },
      { id: s.id },
    )
    return row
  })
}

function getTrueKeys(obj: Record<string, boolean>): string {
  // Filter the keys based on their boolean value being true and then join them into a string
  if (!obj) return '-'
  return Object.keys(obj)
    .filter((key) => obj[key])
    .join(', ')
}

const getSelectLabel = (options: any[], value: any) => {
  const option = options.find((v: any) => v.value == value)
  // - no selection
  return option?.label ?? '-'
}
