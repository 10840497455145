import { ThemeProvider, Typography } from '@mui/material'
import React, { Component } from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { LoginPage, ROUTE_LOGIN } from './pages/login/LoginPage'
import { theme } from './Theme'
import {
  NonAuthenticatedFormPage,
  ROUTE_NO_AUTH_FORM,
} from './pages/nonauthenticated_form/NonAuthenticatedFormPage'

import { ConfirmationDialog } from './components/dialogs/confirmation-dialog/ConfirmationDialog'
import { setup } from '../core/feature_flags/firebase-feature-flag'
import {
  OrgSelectionPage,
  ROUTE_ORG_SELECTION,
} from './pages/org_selection/OrgSelectionPage'
import {
  SubmissionsPage,
  ROUTE_SUBMISSIONS,
  ROUTE_CORRECTIVE_ACTIONS,
} from './pages/submissions/SubmissionsPage'

import { ROUTE_USERS, UsersPage } from './pages/users/UsersPage'
import { Submission } from './pages/submission/Submission'
import ViewSubmission from './pages/submission/ViewSubmission'
import NewSubmission from './pages/submission/NewSubmission'
import EditSubmission from './pages/submission/EditSubmission'
import NotificationsPage, {
  ROUTE_NOTIFICATIONS,
} from './pages/notifications/NotificationsPage'
import NotFoundPage from './pages/NotFoundPage'
import { DemoPage, ROUTE_DEMO } from './pages/demo/DemoPage'
import { LevelsPage, ROUTE_LEVELS } from './pages/levels/LevelsPage'
import { ProjectsPage } from './pages/projects/ProjectsPage'
import { DashboardPage, ROUTE_DASHBOARD } from './pages/dashboard/DashboardPage'
import { ComponentDocs } from './pages/components-docs/ComponentsDocs'
import { InnerPageContainerDoc } from './pages/components-docs/markdown/pages/InnerPageContainerDoc'
import { LevelsForm } from './components/forms/Levels/LevelsForm'
import { ProjectsForm } from './components/forms/Levels/ProjectsForm'
import EdifySearchDropdownDoc from './pages/components-docs/markdown/pages/EdifySearchDropdownDoc'
import { LevelPage } from './pages/level/LevelPage'
import { ProjectPage } from './pages/project/ProjectPage'
import { PersonProfilePage, ROUTE_PERSON_PROFILE } from './pages/person_profile/PersonProfilePage'
import { CorrectiveActionPage } from './pages/corrective-action/CorrectiveActionPage'

import { GoalsPage, ROUTE_GOALS } from './pages/goals/GoalsPage'
import { GoalPage } from './pages/goal/GoalPage'
import { GoalFormPage } from './pages/goal/GoalFormPage'
import { UserFormPage } from './pages/user/UserFormPage'
import { CorrectiveActionsPage } from './pages/corrective-actions/CorrectiveActionsPage'
import {
  ContractorsPage,
  ROUTE_CONTRACTORS,
} from './pages/contractors/ContractorsPage'
import { ContractorFormPage } from './pages/contractor/ContractorFormPage'
import { ContractorPage } from './pages/contractor/ContractorPage'
import HomePage, { ROUTE_HOME } from './pages/home/HomePage'
import { ROUTE_FORMS } from './components/banners/drawer/FormLinks'
import { FormsPage } from './pages/forms/FormsPage'
import NewCorrectiveActionPage from './pages/corrective-actions/NewCorrectiveActionPage'
import { SubmissionPageViewModel } from './pages/submission/SubmissionViewModel'
import { ContactSalesDialog } from './pages/home/ContactSalesDialogue'
import InternalSelfSignUp from './pages/demo/InternalSelfSignUp'
import {
  AdminOrgsPage,
  ROUTE_ADMIN_ORGS,
} from './pages/admin-org-selection/AdminOrgsPage'
import { GlobalProvider } from '../providers/GlobalProvider'

import ReactGA from 'react-ga4'
import { FormSettingsPage } from './pages/formSettings/FormSettingsPage'
import OrgSettingsPage, {
  ROUTE_ORG_SETTINGS,
} from './pages/org-settings/OrgSettingsPage'
import { ROUTE_ROLES, Roles } from './pages/roles/Roles'
import { RoleSettings } from './pages/role-settings/RoleSettings'
import GlobalFormSettingsPage from './pages/forms/GlobalFormSettingsPage'
import { ConfirmationDialogV2 } from './components/dialogs/confirmation-dialog/ConfirmationDialogV2'
import OrgFormSettingsPage from './pages/org-settings/OrgFormSettingsPage'
import { QRCodeDialogV2 } from './components/dialogs/qr-code/QRCodeDialog'
import AnonymousSubmission from './pages/submission/AnonymousSubmission'
import OrgPageWrapper from './pages/wrappers/OrgPageWrapper'
import SSOCheckPage, { ROUTE_SSO_CHECK } from './pages/login/SSOCheckPage'
import PrivatePage from './pages/wrappers/PrivatePage'
import NewLocation from './pages/location/NewLocation'
import Location from './pages/location/Location'
import { ContactPage, ROUTE_CONTACTS } from './pages/contact/ContactPage'
import { ContactFormPage } from './pages/contact/ContactFormPage'
import { ConfirmationDialogV3 } from './components/dialogs/confirmation-dialog/ConfirmationV3'
import DocumentLibraryPage, {
  ROUTE_DOCUMENT_LIBRARY,
} from './pages/document-library/DocumentLibraryPage'
import { CertificatesForm } from './pages/certificates/CertificatesForm'
import { AnonUserPage } from './pages/user/AnonUserPage'
import { OneConfirmationDialog } from './components/dialogs/confirmation-dialog/OneButtonConfrimationDialog'
import { UserPage } from './pages/user/UserPage'
import { UserPageTemp } from './pages/user/UserPageTemp'
import { ContractorPageTemp } from './pages/contractor/ContractorPageTemp'
import { RolePage } from './pages/role/RolePage'
import { RoleFormPage } from './pages/role/RoleFormPage'
import { PersonProfileForm } from './components/forms/PersonProfileForm/PersonProfileForm'

export const App: React.FC = () => {
  const TRACKING_ID = 'G-DMLYDDB6E1'
  ReactGA.initialize(TRACKING_ID)
  setup()
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route path='/trial' element={<InternalSelfSignUp />} />
          <Route
            path='/anonymous/submissions'
            element={<AnonymousSubmission />}
          />
          <Route
            path={`/anonymous${ROUTE_USERS}/:userId`}
            element={
              <GlobalProvider>
                <AnonUserPage />
              </GlobalProvider>
            }
          />
          <Route path={ROUTE_SSO_CHECK} element={<SSOCheckPage />} />
          <Route
            element={
              <GlobalProvider>
                <PrivatePage />
              </GlobalProvider>
            }
          >
            <Route path='/' element={<OrgSelectionPage />} />
            <Route path={ROUTE_ORG_SELECTION} element={<OrgSelectionPage />} />

            <Route path={ROUTE_ADMIN_ORGS} element={<AdminOrgsPage />} />

            {/* Inside Selected Org Section all the components will have access to the OrganizationProvider  */}
            <Route element={<OrgPageWrapper />}>
              <Route path={ROUTE_HOME} element={<HomePage />} />
              <Route path={ROUTE_DASHBOARD} element={<DashboardPage />} />
              <Route
                path={`${ROUTE_ORG_SETTINGS}`}
                element={<OrgSettingsPage />}
              />
              <Route
                path={`/:formCategory${ROUTE_SUBMISSIONS}/:formId/submission/:id`}
                element={<ViewSubmission />}
              />

              {/* Submission/Creative Actions */}
              <Route
                // this is really the form id
                path={`/:formCategory${ROUTE_SUBMISSIONS}/:formId`}
                element={<SubmissionsPage />}
              />
              <Route
                element={<Submission />}
                path={`/:formCategory${ROUTE_SUBMISSIONS}/:formId`}
              >
                {/* <Route path={'submission/:id'} element={<ViewSubmission />} /> */}
                <Route path={'submission/new'} element={<NewSubmission />} />

                <Route
                  path={'submission/:id/edit'}
                  element={<EditSubmission />}
                />
              </Route>
              <Route
                path={`/:formCategory${ROUTE_SUBMISSIONS}/:formId/settings`}
                element={<FormSettingsPage />}
              />
              {/* End Submission/ */}
              <Route element={<FormsPage />} path={`${ROUTE_FORMS}/all`} />
              <Route
                element={<OrgFormSettingsPage />}
                path={`${ROUTE_FORMS}/settings`}
              />

              {/* Organization Pages */}
              <Route path={`${ROUTE_USERS}`} element={<UsersPage />} />
              <Route path={`${ROUTE_USERS}/new`} element={<UserFormPage />} />
              <Route
                path={`${ROUTE_USERS}/:userId`}
                element={<UserPageTemp isEdit={false} />}
              />
              <Route
                path={`${ROUTE_USERS}/:userId/edit`}
                element={<UserPageTemp isEdit={true} />}
              />
              <Route
                path={`${ROUTE_USERS}/:userId/certificates/new`}
                element={<CertificatesForm />}
              />
              <Route
                path={`${ROUTE_USERS}/:userId/certificates/:id/edit`}
                element={<CertificatesForm />}
              />

              <Route
                path={`${ROUTE_PERSON_PROFILE}/org`}
                element={<PersonProfilePage />}
              />

              <Route
                path={`${ROUTE_PERSON_PROFILE}/new`}
                element={<UserFormPage />}
              />

              <Route
                path={ROUTE_DOCUMENT_LIBRARY}
                element={<DocumentLibraryPage />}
              />

              <Route
                path={`${ROUTE_DOCUMENT_LIBRARY}/:id`}
                element={<DocumentLibraryPage />}
              />

              {/* Contractor Pages */}
              <Route
                path={`${ROUTE_CONTRACTORS}`}
                element={<ContractorsPage />}
              />
              <Route
                path={`${ROUTE_CONTRACTORS}/new`}
                element={<ContractorFormPage />}
              />
              <Route
                path={`${ROUTE_CONTRACTORS}/:contractorId`}
                element={<ContractorPageTemp />}
              />

              <Route
                path={`${ROUTE_CONTRACTORS}/:contractorId/edit`}
                element={<ContractorPageTemp isEdit={true} />}
              />

              {/* Contact Pages */}

              <Route
                path={`${ROUTE_CONTRACTORS}/:contractorId${ROUTE_CONTACTS}/:contactId`}
                element={<ContactPage />}
              />

              <Route
                path={`${ROUTE_CONTRACTORS}/:contractorId${ROUTE_CONTACTS}/:contactId/edit`}
                element={<ContactPage isEdit={true} />}
              />

              <Route
                path={`${ROUTE_CONTACTS}/:contractorId/edit`}
                element={<ContractorFormPage />}
              />

              <Route
                path={`${ROUTE_CONTRACTORS}/:contractorId${ROUTE_CONTACTS}/new`}
                element={<ContactFormPage />}
              />

              {/* Levels just 1 form now */}
              <Route path={`${ROUTE_LEVELS}/1`} element={<LevelsPage />} />
              {/*  Level 1Test form */}
              <Route path={`${ROUTE_LEVELS}/1/new`} element={<LevelsForm />} />
              <Route
                path={`${ROUTE_LEVELS}/1/:id/edit`}
                element={<LevelsForm />}
              />
              <Route path={`${ROUTE_LEVELS}/1/:id`} element={<LevelPage />} />
              <Route
                path={`${ROUTE_LEVELS}/1/:levelId/level/:id/edit`}
                element={<LevelsForm />}
              />
              {/*  Project Test form */}
              <Route
                path={`${ROUTE_LEVELS}/1/:id/new`}
                element={<ProjectsForm />}
              />
              {/* this is to go to projects page from level1 */}
              <Route
                path={`${ROUTE_LEVELS}/1/:levelId/project/:id`}
                element={<ProjectPage />}
              />
              {/* Projects */}
              <Route path={'/projects'} element={<ProjectsPage />} />
              <Route path={'/projects/:id'} element={<ProjectPage />} />
              <Route path={'/projects/new'} element={<ProjectsForm />} />
              <Route path={'/projects/:id/edit'} element={<ProjectsForm />} />

              {/* Do we want to nest and OUTLET? */}
              <Route
                path={'/projects/:projectId/locations/:id'}
                element={<Location />}
              />
              <Route
                path={'/projects/:projectId/locations/new'}
                element={<NewLocation />}
              />

              {/* End Organization Pages */}

              <Route
                path={`${ROUTE_CORRECTIVE_ACTIONS}`}
                element={<CorrectiveActionsPage />}
              />
              <Route
                path={`${ROUTE_CORRECTIVE_ACTIONS}/new/:correctiveActionFormId`}
                element={
                  <SubmissionPageViewModel>
                    <NewCorrectiveActionPage />
                  </SubmissionPageViewModel>
                }
              />
              <Route
                path={`${ROUTE_CORRECTIVE_ACTIONS}/:id`}
                element={<CorrectiveActionPage />}
              />

              {/* Goal and Controls */}
              <Route path={ROUTE_GOALS} element={<GoalsPage />} />
              <Route path={`${ROUTE_GOALS}/:id`} element={<GoalPage />} />
              <Route
                path={`${ROUTE_GOALS}/:id/edit`}
                element={<GoalFormPage />}
              />
              <Route path={`${ROUTE_GOALS}/new`} element={<GoalFormPage />} />

              <Route path={ROUTE_ROLES} element={<Roles />} />
              <Route path={`${ROUTE_ROLES}/new`} element={<RoleFormPage />} />
              <Route path={`${ROUTE_ROLES}/:id/edit`} element={<RoleFormPage />} />
              <Route path={`${ROUTE_ROLES}/:id/duplicate`} element={<RoleFormPage isDuplicate={true} />} />
              <Route path={`${ROUTE_ROLES}/:id`} element={<RolePage />} />
              <Route
                path={`${ROUTE_FORMS}/settings`}
                element={<GlobalFormSettingsPage />}
              />
              <Route path={ROUTE_DEMO} element={<DemoPage />} />
              <Route
                path={ROUTE_NOTIFICATIONS}
                element={<NotificationsPage />}
              />

              {/* COMPONENT DEMO  Can Comment out in main*/}
              <Route element={<ComponentDocs />} path={'/cd'}>
                <Route
                  path={'/cd/innerpage'}
                  element={<InnerPageContainerDoc />}
                />
                <Route
                  path={'/cd/edifySearchDropdown'}
                  element={<EdifySearchDropdownDoc />}
                />
              </Route>
            </Route>
            {/* End Selected Org Section these components will not have access to the OrganizationProvider  */}
          </Route>
          {/* End Private (logged in) */}

          <Route path={ROUTE_LOGIN} element={<LoginPage />} />
          <Route
            path={ROUTE_NO_AUTH_FORM}
            element={<NonAuthenticatedFormPage />}
          />

          {/* 404 page */}
          <Route path='*' element={<NotFoundPage />} />
        </Routes>
      </BrowserRouter>
      <AppDialogs />
    </ThemeProvider>
  )
}

const AppDialogs: React.FC = () => {
  return (
    <>
      <OneConfirmationDialog />
      <ConfirmationDialog />
      <ConfirmationDialogV2 />
      <ConfirmationDialogV3 />
      <QRCodeDialogV2 />
      <ContactSalesDialog />
    </>
  )
}
