//ED-1383 (EDIT) https://edifyai.atlassian.net/browse/ED-1383
// ED-1389 (create) https://edifyai.atlassian.net/browse/ED-1389
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import PageContainer from '../../page/PageContainer'
import { useOrganizationProvider } from '../../../../providers/OrganizationProvider'
import InnerPageContainer from '../../inner-page-container/InnerPageContainer'
import { Box, Divider, Input } from '@mui/material'
import { EdifyFieldLabel, EdifyFormField } from '../../form'
import { EdifyButton } from '../../buttons'
import {
  ErrorToast,
  SuccessToast,
} from '../../../../core/utils/toast-notifications/ToastNotifications'
import EdifyDatePicker from '../../form/EdifyDatePicker'
import {
  createProject,
  getProjectById,
  updateProject,
} from '../../../../domain/domain'

import EdifySearchDropdown, {
  ISearchResult,
} from '../../form/EdifySearch/EdifySearchDropdown'
import { FieldErrors, Resolver, useForm } from 'react-hook-form'
import FormErrorText from '../../form/FormErrorText'
import dayjs from 'dayjs'
import EdifyImageUploader from '../../form/EdifyImage/EdifyImageUploader'
import EdifyAttachmentViewer from '../../form/EdifyImage/EdifyImageViewer'
import LocationForm from '../../../pages/location/LocationForm'
import { ROUTE_PROJECTS } from '../../../pages/projects/ProjectsPage'
import { ETypography } from '../../fonts/ETypography'
import {
  ProjectPageViewModel,
  useProjectPageViewModel,
} from '../../../pages/project/ProjectPageViewModel'
import LocationCSVUpload from '../../../pages/location/LocationCSVUpload'
import ErrorContainer from '../../error/ErrorContainer'
import usePermission from '../../../../core/hooks/usePermission'
import { ERole } from '../../../../domain/interfaces/IRole'
import { globalSearch } from '../../../../domain/usecases/utils/UtilsUsecasses'

type IProjectInputs = {
  name: string
}

export type IProjectFormData = {
  name?: string
  startDate?: string | null
  endDate?: string | null
  pointOfContact?: string | null
  levelOneIds?: string[] | null
  imageURL?: string | null
}

const _ProjectsForm: React.FC = () => {
  // if id it is edit
  const { getHierarchyName, allLevels, flags } = useOrganizationProvider()
  const {
    locations,
    locationsLoading,
    locationsError,
    loadLocations,
    projectName,
    setLocations,
  } = useProjectPageViewModel()
  const { id } = useParams()
  const canCreateLocation = usePermission(ERole.LocationCreate)
  const [projectId, setProjectId] = useState<string | undefined>(id)
  const navigate = useNavigate()
  const [startDate, setStartDate] = useState<string | null>(null)
  const [endDate, setEndDate] = useState<string | null>(null)
  const [projectLoading, setProjectLoading] = useState<boolean>(false)
  const [pointOfContact, setPointOfContact] = useState<ISearchResult>()
  const [levels, setLevels] = useState<ISearchResult[]>([])
  const [projectError, setProjectError] = useState<string | undefined>(
    undefined,
  )
  const [submitting, setSubmitting] = useState<boolean>(false)
  const [submissionError, setSubmissionError] = useState<string | undefined>()
  const [imageURL, setImageURL] = useState<string | null>(null)

  const startDateChanged = (date: dayjs.Dayjs | null) => {
    const dateString = date?.format('MM/DD/YYYY') ?? null
    setStartDate(dateString)
  }
  const endDateChanged = (date: dayjs.Dayjs | null) => {
    const dateString = date?.format('MM/DD/YYYY') ?? null
    setEndDate(dateString)
  }
  const [singleCreate, setSingleCreate] = useState(true)
  const [hideForm, setHideForm] = useState(true)

  const level0Name = getHierarchyName(0)

  // Validation
  const resolver: Resolver<IProjectInputs> = async (values) => {
    const errors: FieldErrors<IProjectInputs> = {}
    if (!values.name) {
      errors.name = {
        type: 'required',
        message: 'Name is required',
      }
    }
    // Add more validations here if needed
    return {
      values,
      errors,
    }
  }
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IProjectInputs>({
    resolver,
    mode: 'onChange',
  })

  /**
   * Handles the form submission when the form is valid.
   * will run resolver validations before submitting
   * @param {Object} data - The form data submitted by the user.
   * @returns {void}
   */
  const onSubmit = handleSubmit((data) => {
    // react-hook-form will check for errors here and return
    // only checks name
    const { name } = data
    const pointOfContactId = pointOfContact?.id ?? null
    const levelIds = levels.map((l) => l.id)
    const formData: IProjectFormData = {
      // Only name is requires
      name,
      endDate,
      startDate,
      imageURL,
      levelOneIds: levelIds,
      pointOfContact: pointOfContactId != '' ? pointOfContactId : null,
    }
    setSubmitting(true)
    setSubmissionError(undefined)
    if (projectId) handleUpdate(projectId, formData)
    else handleCreate(formData)
  })

  const handleUpdate = async (id: string, formData: IProjectFormData) => {
    // TODO use projectUpdate when ED-1387 is merged that
    // has the call and tests..
    const res = await updateProject(id, formData)
    setSubmitting(false)
    if (res.isLeft()) {
      setSubmissionError(
        res.left?.message ?? 'A unknown error occurred, please try again later',
      )
      ErrorToast({ title: 'Error saving new changes.' })
      return
    }
    SuccessToast({ title: `Successfully Updated ${projectName}.` })
    // WHAT TODO HERE?

    // navigate(-1)
  }

  const handleCreate = async (formData: IProjectFormData) => {
    const res = await createProject(formData)
    setSubmitting(false)
    if (res.isLeft()) {
      setSubmissionError(
        res.left?.message ?? 'A unknown error occurred, please try again later',
      )
      ErrorToast({ title: `Error adding ${level0Name}` })
      return
    }
    setProjectId(res.right!.id)
    navigate(`${ROUTE_PROJECTS}/${res.right!.id}/edit`)
    SuccessToast({ title: `New ${level0Name} has been added.` })
    // WHAT TODO HERE?
    // navigate(-1)
  }
  const contactSelected = (projects: ISearchResult[]) => {
    setPointOfContact(projects[0])
  }
  const levelsSelected = (projects: ISearchResult[]) => {
    setLevels(projects)
  }

  const getProject = async () => {
    setProjectError(undefined)
    setProjectLoading(true)
    const res = await getProjectById(id!)
    setProjectLoading(false)
    if (res.isLeft() || !res.right) {
      setProjectError('Error loading form.')
      return
    }
    // sets name field
    reset({ name: res.right.name })

    // TODO: Dates not setting on Update
    setStartDate(res.right?.createdAt)
    setEndDate(res.right?.endDate)
    setImageURL(res.right?.imageURL ?? null)

    setPointOfContact({
      name: res.right.pointOfContact?.fullName || '',
      id: res.right.pointOfContact?.id || '',
    })

    const levelData = res.right.levels
      ? res.right.levels.map((l) => {
        return {
          id: l.id,
          name: l.name,
        }
      })
      : []
    setLevels(levelData)
  }

  const imageUploaded = async (imageURL: string | null) => {
    setSubmissionError(undefined)
    setImageURL(imageURL ?? null)
    setSubmitting(false)
  }
  const deleteImage = () => {
    setImageURL(null)
  }

  useEffect(() => {
    // Edit
    if (id) {
      getProject()
    }
  }, [id])

  const getBreadCrumbs = () => {
    const defaultCrumbs = [
      { title: 'Organization', to: '' },
      { title: 'Projects', to: '/projects' },
    ]
    if (!id) return [...defaultCrumbs, { title: 'New', to: '/projects/new' }]

    return [
      ...defaultCrumbs,
      { title: projectName, to: `/projects/${id}` },
      { title: 'Edit', to: `/projects/${id}/edit` },
    ]
  }

  const renderNewLocationSection = () => {
    if (hideForm && locations && locations.length > 0) {
      return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <EdifyButton
            noBackground
            onClick={() => navigate(`/projects/${projectId}`)}
            title='Finish Adding Locations'
          ></EdifyButton>
          <EdifyButton
            noBackground
            onClick={() => setHideForm(false)}
            title='+ Add Another Location'
          ></EdifyButton>
        </Box>
      )
    }
    return (
      <>
        <ETypography font='HMS' color='gray700'>
          {/* {`${locations?.length} ${pluralize('Location', locations?.length)}`} */}
          Add Location(s)
        </ETypography>
        <Box sx={{ marginBottom: '24px', marginTop: '24px', gap: 24 }}>
          <EdifyButton
            title='Add One'
            secondary={!singleCreate}
            onClick={() => setSingleCreate(true)}
          />
          <EdifyButton
            // disabled
            buttonStyle={{ marginLeft: '12px' }}
            title='CSV Upload'
            secondary={singleCreate}
            onClick={() => setSingleCreate(false)}
          />
          <EdifyButton
            // disabled
            noBackground
            buttonStyle={{ marginLeft: '12px' }}
            title='Skip Add Location'
            onClick={() => navigate(`/projects/${projectId}`)}
          />
        </Box>
        {singleCreate && projectId ? (
          <LocationForm
            projectId={projectId}
            setLocations={setLocations}
            locations={locations}
            setHideForm={setHideForm}
          />
        ) : (
          <LocationCSVUpload
            projectId={projectId}
            loadLocations={loadLocations}
          />
        )}
      </>
    )
  }

  const renderLocationsSection = () => {
    if(!canCreateLocation) return null
    if (locationsError) {
      return (
        <ErrorContainer>
          <ETypography font='MM' color='gray700'>
            Error Loading Location
          </ETypography>
        </ErrorContainer>
      )
    }
    if (locationsLoading) {
      return (
        <ETypography font='MM' color='gray700'>
          Loading Location
        </ETypography>
      )
    }

    if (!projectId) {
      return (
        <ETypography font='SM' color='gray400' sx={{ marginTop: '24px' }}>
          Save project above to add a Location
        </ETypography>
      )
    }
    if (locations && locations.length == 0) {
      return renderNewLocationSection()
    }
    
    return (
      <>
        {locations && locations.length > 0 && (
          <>
            {locations?.map((l) => {
              return (
                <Box key={`location-${l.id}`} sx={{ marginTop: '24px' }}>
                  <LocationForm projectId={projectId!} initialLocation={l} />
                </Box>
              )
            })}
          </>
        )}
        {/* <Divider sx={{ marginTop: '12px', marginBottom: '12px' }} /> */}
        {renderNewLocationSection()}
      </>
    )
  }

  return (
    <PageContainer
      title={id ? `Edit ${projectName}` : `Create New ${level0Name}`}
      breadCrumbs={getBreadCrumbs()}
    >
      <form onSubmit={onSubmit}>
        <InnerPageContainer
          innerPageLoading={projectLoading}
          innerPageError={projectError}
          innerPageReload={getProject}
          sx={{ padding: '24px', marginBottom: '24px' }}
          headerTitle={`${level0Name} Details`}
        >
          {submissionError && (
            <InnerPageContainer
              sx={{ padding: '12px', marginTop: '12px' }}
              innerPageError={submissionError}
              close={() => setSubmissionError(undefined)}
            />
          )}
          <EdifyFormField>
            <EdifyFieldLabel required label={'Name'} />
            <Input
              sx={{ width: '100%' }}
              data-testid='ProjectName'
              disableUnderline
              placeholder={`Enter ${projectName} Name`}
              className={`form-control ${errors?.name ? 'error' : ''}`}
              {...register('name', { required: true })}
            />
            {errors.name && (
              <FormErrorText>This field is required</FormErrorText>
            )}
          </EdifyFormField>
          <Box sx={{ display: 'flex', gap: '24px' }}>
            <EdifyFormField sx={{ flex: 1 }}>
              <EdifyFieldLabel label='START DATE' />
              <EdifyDatePicker date={startDate} setDate={startDateChanged} />
            </EdifyFormField>
            <EdifyFormField sx={{ flex: 1 }}>
              <EdifyFieldLabel label='END DATE' />
              <EdifyDatePicker date={endDate} setDate={endDateChanged} />
            </EdifyFormField>
          </Box>
          <Box sx={{ display: 'flex' }}>
            <EdifyFormField sx={{ flex: 1 }}>
              <EdifyFieldLabel label='Point Of Contact' />
              <EdifySearchDropdown
                pillListWidth={'100%'}
                sx={{ flex: 1 }}
                initialSelectedItems={pointOfContact ? [pointOfContact] : []}
                width={495}
                onSelect={contactSelected}
                nameKey='fullName'

                limit={8}
                globalSearchKey='users'
                globalSearchPayload={{
                  entities: ['users'],
                  properties: ['id'],
                  returns: ['id','fullName'],
                }}
                searchFunction={globalSearch}
              />
            </EdifyFormField>
          </Box>
          <Box sx={{ display: 'flex', gap: '24px' }}>
            <EdifyFormField sx={{ flex: 1 }}>
              <EdifyFieldLabel label={`Select ${getHierarchyName(1, true)}`} />
              <EdifySearchDropdown
                pillListWidth={'100%'}
                multiSelect
                width={495}
                defaultItems={allLevels.map((p) => {
                  return { id: p.id, name: p.name }
                })}
                globalSearchKey='levels'
                globalSearchPayload={{
                  entities: ['levels'],
                  properties: ['id'],
                  returns: ['id','name'],
                }}
                searchFunction={globalSearch}
                onSelect={levelsSelected}

                sx={{ flex: 1 }}
                initialSelectedItems={levels}
      
              />
              <Box sx={{ width: '100%' }}></Box>
            </EdifyFormField>
          </Box>
          <EdifyFormField>
            <EdifyFieldLabel
              label={`${getHierarchyName(
                0,
              )} Logo, (optional, recommended size: 160x160 px)`}
            />
            {imageURL ? (
              <EdifyAttachmentViewer
                attachment={imageURL}
                onDelete={deleteImage}
              />
            ) : (
              <EdifyImageUploader
                edifyUI
                singleFile
                uploadUrl='/fileUploads/project'
                handleLoad={imageUploaded}
                dataKey='anyFile'
                // Move back to Array
                // decodeResponse={(object: any) => object.data.originalUrl}
                handleOnAddStart={() => setSubmitting(true)}
              />
            )}
          </EdifyFormField>
        </InnerPageContainer>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <EdifyButton
            noBackground
            title='Cancel'
            onClick={() => navigate('/projects')}
          />
          <Box>
            <Box>
              <EdifyButton
                data-testid={'SubmissionButton'}
                loading={submitting}
                disabled={submitting}
                primary
                type='submit'
                title={id ? `Update ${level0Name}` : `Save New ${level0Name}`}
              />
            </Box>
          </Box>
        </Box>
      </form>
      {flags.isLocationEnabled && (
        <>
          <Divider sx={{ marginTop: '12px', marginBottom: '12px' }} />
          {renderLocationsSection()}
        </>
      )}
    </PageContainer>
  )
}

export const ProjectsForm: React.FC = () => {
  return (
    <ProjectPageViewModel>
      <_ProjectsForm />
    </ProjectPageViewModel>
  )
}
