import React, { useState } from 'react'
import { Box, Button, Grid, Input } from '@mui/material'
import InnerPageContainer from '../../components/inner-page-container/InnerPageContainer'
import SubLocation from './SubLocation'
import { EdifyFieldLabel, EdifyFormField } from '../../components/form'
import { ILocation } from '../../../domain/interfaces/ILocation'
import MapDemo from './MapDemo'
import { useOrganizationProvider } from '../../../providers/OrganizationProvider'
import { EdifyButton } from '../../components/buttons'
import ImageWithToken from '../../components/form/EdifyImage/ImageWithToken'
import { ERole } from '../../../domain/interfaces/IRole'
import usePermission from '../../../core/hooks/usePermission'

interface Props {
  location: ILocation
  locationLoading?: boolean
  locationError?: string | undefined
  handleEditClicked?: () => void
}
const LocationCard: React.FC<Props> = ({ location, handleEditClicked }) => {
  const { flags } = useOrganizationProvider()
  const canEdit = usePermission(ERole.LocationEdit, false, 'TODO IS MEMBER ID')
  const [url, setUrl] = useState<string | null>(location.attachments[0])

  return (
    <>
      <InnerPageContainer
        headerTitle={location.name}
        sx={{ minHeight: '504px' }}
        renderRightItems={() =>
          handleEditClicked && canEdit ? (
            <EdifyButton
              hide={!canEdit}
              noBackground
              title={'Edit'}
              onClick={handleEditClicked}
            />
          ) : (
            <></>
          )
        }
      >
        <Grid container spacing={'24px'}>
          <Grid item sm={12} md={12} lg={6}>
            <Box sx={{ height: '380px', marginTop: '24px' }}>
              <MapDemo
                disabled={true}
                address={location.address}
                latLng={{ lat: location.lat, lng: location.long }}
              />
            </Box>
          </Grid>
          <Grid item sm={12} md={12} lg={6}>
            <EdifyFormField>
              <EdifyFieldLabel label='Site Photo (JPG OR PNG)' />
              {url && (
                <ImageWithToken
                  // style={{ marginTop: '24px' }}
                  height='370'
                  width='100%'
                  src={url}
                  alt='location'
                />
              )}
            </EdifyFormField>
          </Grid>
        </Grid>
      </InnerPageContainer>
      {flags.isSubLocationEnabled && location && (
        <SubLocation locationId={location.id!} />
      )}
    </>
  )
}
export default LocationCard
