// ED-1387 https://edifyai.atlassian.net/browse/ED-1387
import React from 'react'
import {
  OrganizationProviderProps,
  useOrganizationProvider,
} from '../../../providers/OrganizationProvider'
import { useNavigate, useParams } from 'react-router-dom'
import PageContainer from '../../components/page/PageContainer'
import {
  ProjectPageViewModel,
  useProjectPageViewModel,
} from './ProjectPageViewModel'
import InnerPageContainer from '../../components/inner-page-container/InnerPageContainer'
import { Box } from '@mui/material'
import LevelHeader from '../../components/cards/LevelHeader'
import EdifyContactInfo from '../../components/cards/EdifyContactInfo'
import { AppBorders, AppColors } from '../../Theme'
import { dateToMMMDYYYY } from '../../../core/core'
import EdifyIconButton from '../../components/buttons/EdifyIconButton/EdifyIconButton'
import { PinActiveIcon } from '../../../core/components/icons/PinActiveIcon'
import { PinIcon } from '../../../core/components/icons/PinIcon'
import {
  LevelsPageViewModel,
  LevelsPageViewModelProps,
  useLevelsPageViewModel,
} from '../levels/LevelsViewModel'
import LevelsDataGrid from '../levels/LevelsDataGrid'
import { normalizeLevelsForDataGrid } from '../../components/datagrid/gridData'
import EdifyImageUploader from '../../components/form/EdifyImage/EdifyImageUploader'
import { ErrorToast } from '../../../core/utils/toast-notifications/ToastNotifications'
import { ETypography } from '../../components/fonts/ETypography'
import { updateProject } from '../../../domain/domain'
import ProjectUserDataGrid from './ProjectUsersDataGrid'
import { ERole } from '../../../domain/interfaces/IRole'
import usePermission from '../../../core/hooks/usePermission'
import LocationsDataGrid from '../locations/LocationsDataGrid'
import { ROUTE_PROJECTS } from '../projects/ProjectsPage'
import ImageWithToken from '../../components/form/EdifyImage/ImageWithToken'
import withPermission from '../../../core/hooks/withPermission'
import { PermissionButton } from '../../components/buttons/edifyButton/PermissionButton'
import { getAssignedUserId } from '../../../data/data'
import NotFoundPage from '../NotFoundPage'
const _ProjectPage = () => {
  const {
    hierarchiesLoading,
    hierarchiesError,
    getHierarchyName,
    flags,
    currentUser
  }: OrganizationProviderProps = useOrganizationProvider()
  const levelVM: LevelsPageViewModelProps = useLevelsPageViewModel()
  const { id, levelId } = useParams()
  const isProjectAssignedUserId = getAssignedUserId(currentUser,'projects', id ?? '')

  const canEdit = usePermission(ERole.ProjectEdit, false, isProjectAssignedUserId)
  const canViewProject = usePermission(ERole.ProjectRead, false, isProjectAssignedUserId)
  const canViewUsers = usePermission(ERole.UserFeatureAccess)
  const canViewLocations = usePermission(
    ERole.LocationRead)
  const canCreateLocations = canEdit && usePermission(
    ERole.LocationCreate)

  // BE filters out levels that are not assigned to the project if role is 1
  const canViewLevel = usePermission(ERole.LevelRead)
  const vm = useProjectPageViewModel()
  const navigate = useNavigate()

  const [projectImage, setProjectImage] = React.useState<string | undefined>(
    vm.project?.imageURL,
  )

  const getRows = () => {
    return levelVM.levels ? normalizeLevelsForDataGrid(levelVM.levels) : []
  }

  const getBreadCrumbs = () => {
    let levelName = 'loading'
    // find level where we navigated from
    if (vm.project && vm.project.levels!) {
      const level = vm.project.levels.find((l) => l.id == levelId)
      levelName = level?.name ?? 'level'
    }
    if (levelId) {
      return [
        { title: 'Organization', to: '' },
        { title: getHierarchyName(1, true), to: '/levels/1' },
        { title: levelName, to: `/levels/1/${levelId}` },
        {
          title: vm.projectName,
          to: `/levels/1/${levelId}/project/${vm.project?.id ?? ''}`,
        },
      ]
    }
    return [
      { title: 'Organization', to: '' },
      { title: getHierarchyName(0, true), to: '/projects' },
      { title: vm.projectName, to: `/projects/${id}` },
    ]
  }

  const edit = () => {
    if (id) {
      navigate(`/projects/${id}/edit`)
    }
  }

  const imageUploaded = async (imageURL: string) => {
    setProjectImage(imageURL)
    const res = await updateProject(id!, {
      imageURL,
    })
    if (res.isLeft()) {
      ErrorToast({ title: 'Error Uploading Photo.' })
      setProjectImage(undefined)
    }
  }
  const getTitle = (rowCount: number) => {
    return rowCount == 1 ? '1 Member Involved' : `${rowCount} Members Involved`
  }

  const favorite = vm.project?.favorited
  const inactive = !vm.project?.active
  const image = vm.project?.imageURL ?? projectImage ?? undefined

  if(!canViewProject) return <NotFoundPage />

  return (
    <PageContainer
      mainPageError={hierarchiesError}
      mainPageLoading={hierarchiesLoading}
      mainPageReload={vm.getProject}
      breadCrumbs={getBreadCrumbs()}
      topActions={
        canEdit
          ? [
            { title: 'Edit', callback: edit },
            {
              title: 'Delete',
              callback: () => vm.showDeleteConfirmation([vm.project!.id]),
            },
          ]
          : []
      }
    >
      <InnerPageContainer
        innerPageError={vm.projectError}
        innerPageLoading={vm.projectLoading}
        innerPageReload={vm.getProject}
        sx={{ padding: '24px', marginBottom: '24px' }}
      >
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row',
          }}
        >
          <Box
            style={{
              display: 'flex',
              flex: 1,
              flexDirection: 'column',
              marginBottom: '24px',
            }}
          >
            <LevelHeader
              favorite={favorite}
              inactive={inactive}
              text={vm.projectName}
            />
            {/* Uncomment when BE supports address */}
            {/* <ETypography
              font='HXXSDS'
              color={inactive ? 'gray25' : 'gray600'}
              sx={{
                marginTop: '4px',
              }}
            >
              No address
            </ETypography> */}
            <Box
              sx={{
                display: 'flex',
                flex: 1,
                flexDirection: 'row',
                marginTop: '24px',
              }}
            >
              <_DateSection
                inactive={inactive}
                title={'Date Started'}
                date={vm.project?.startDate ?? '-'}
              />
              <_DateSection
                inactive={inactive}
                title={'Days Remaining'}
                date={''}
                formattedDate={vm.project?.daysRemaining ?? '-'}
              />
            </Box>
            {/*  */}
          </Box>
          <Box sx={{ width: 151, height: 151, position: 'relative' }}>
            <Box
              sx={{
                position: 'absolute',
                top: -10,
                right: -10,
                background: AppColors.baseWhite,
                borderRadius: '5px',
              }}
            >
              <EdifyIconButton
                onClick={favorite ? vm.unSetFavorite : vm.setFavorite}
              >
                {favorite ? <PinActiveIcon /> : <PinIcon />}
              </EdifyIconButton>
            </Box>
            {image ? (
              <ImageWithToken
                style={{ borderRadius: '5px', objectFit: 'cover' }}
                src={image}
                width='151'
                height='151'
              />
            ) : (
              <Box
                sx={{
                  ...AppBorders.primary,
                  width: '151px',
                  height: '151px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {canEdit && (
                  <EdifyImageUploader
                    noUI
                    uploadUrl='/fileUploads/project'
                    singleFile={true}
                    label='Upload Photo'
                    dataKey='anyFile'
                    // Move back to Array
                    // decodeResponse={(object: any) => object.data.originalUrl}
                    handleLoad={imageUploaded}
                  />
                )}
              </Box>
            )}
          </Box>
        </Box>
        <EdifyContactInfo
          name={vm.pointOfContact?.fullName ?? 'NA'}
          email={vm.pointOfContact?.email ?? 'NA'}
          phone={vm.pointOfContact?.phone ?? 'NA'}
          role={vm.pointOfContact?.role ?? 'NA'}
        />
      </InnerPageContainer>

      {/* <LevelsDataGrid
        vm={levelVM}
        buttonTitle={getHierarchyName(0, false)}
        onButtonClick={() => navigate(`/levels/1/${id}/new`)}
      /> */}
      {id && flags.isLocationEnabled && (
        <Box sx={{ marginBottom: '24px' }}>
          {canCreateLocations && (
            <Box
              sx={{
                marginBottom: '24px',
                display: 'flex',
                flexDirection: 'row-reverse',
              }}
            >
              <PermissionButton
                sx={{}}
                requiredPermission={ERole.LocationCreate}
                title='Add Location'
                onClick={() => {
                  navigate(`${ROUTE_PROJECTS}/${id}/locations/new`)
                }}
              />
            </Box>
          )}
          {canViewLocations && <LocationsDataGrid projectId={id} />}
        </Box>
      )}
      {canViewLevel && (
        <>
          <LevelsDataGrid
            vm={levelVM}
            error={levelVM.levelsError}
            title={getHierarchyName(1, false)}
            rows={getRows()}
            checkboxSelection={false}
          />

          <Box sx={{ marginTop: '24px' }}></Box>
        </>
      )}
      {canViewUsers && (
        <>
          <ProjectUserDataGrid
            vm={vm}
            formatTitle={false}
            title={getTitle(vm?.totalUsers)}
            checkboxSelection={false}
          />
        </>
      )}
    </PageContainer>
  )
}

interface _IDateSectionProps {
  title: string
  date: string
  formattedDate?: string
  inactive: boolean
}

const _DateSection: React.FC<_IDateSectionProps> = ({
  title,
  date,
  formattedDate,
  inactive,
}) => {
  // TODO do math and format day remaining
  const dateString = formattedDate ? formattedDate : dateToMMMDYYYY(date)
  return (
    <Box style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
      <ETypography font='XSM' color='gray50'>
        {title}
      </ETypography>
      <ETypography font='HMS' color={inactive ? 'gray25' : 'gray950'}>
        {dateString}
      </ETypography>
    </Box>
  )
}

const ProjectsPageWithPermission = withPermission(
  _ProjectPage,
  ERole.ProjectRead,
)

export const ProjectPage = () => {
  return (
    <ProjectPageViewModel>
      <LevelsPageViewModel>
        <ProjectsPageWithPermission />
      </LevelsPageViewModel>
    </ProjectPageViewModel>
  )
}
