import React from 'react'
import PageContainer from '../../components/page/PageContainer'
import {  useNavigate, useParams } from 'react-router-dom'
import LocationCard from './LocationCard'
import { EdifyButton } from '../../components/buttons'
import LocationForm from './LocationForm'
import {
  LocationPageViewModel,
  useLocationPageViewModel,
} from './LocationViewModel'
import {
  ProjectPageViewModel,
  useProjectPageViewModel,
} from '../project/ProjectPageViewModel'
import { ILocation } from '../../../domain/interfaces/ILocation'
import {
  hideConfirmationDialog,
  showConfirmationDialog,
} from '../../components/dialogs/confirmation-dialog/ConfirmationDialog'
import { deleteLocation } from '../../../domain/domain'
import {
  ErrorToast,
  SuccessToast,
} from '../../../core/utils/toast-notifications/ToastNotifications'
import { ROUTE_PROJECTS } from '../projects/ProjectsPage'
import usePermission from '../../../core/hooks/usePermission'
import { ERole } from '../../../domain/interfaces/IRole'
import withPermission from '../../../core/hooks/withPermission'

function _Location() {
  const { projectId, id } = useParams()
  const { projectName, projectLoading } = useProjectPageViewModel()
  const {
    location,
    locationLoading,
    locationError,
    locationName,
    setLocation,
  } = useLocationPageViewModel()
  const navigate = useNavigate()
  const [isEdit, setIsEdit] = React.useState<boolean>(false)

  const canCreate = usePermission(ERole.LocationCreate)
  // TODO Self option...
  const canEdit = usePermission(ERole.LocationEdit, false, 'TODO IS MEMBER ID')

  const getBreadCrumbs = () => {
    return [
      { title: 'Organization', to: '' },
      { title: 'Projects', to: '/projects' },
      { title: projectName, to: `/projects/${projectId}` },
      {
        title: locationName ?? 'Location',
        to: `/projects/${projectId}/locations/${id}`,
      },
    ]
  }

  const handleEditClicked = () => {
    setIsEdit(!isEdit)
  }
  const handleDeleteClicked = async () => {
    //TODO
    showConfirmationDialog({
      title: 'Confirm Delete',
      message:
        // eslint-disable-next-line quotes
        "Are you sure you want to delete this location and and it's sublocations from the database? This action cannot be undone.",
      cancelText: 'Cancel',
      confirmText: 'Delete',
      onCancel: function (): void {
        hideConfirmationDialog()
      },
      onConfirm: async () => {
        const results = await deleteLocation(id ?? '')
        if (results.isLeft()) {
          ErrorToast({
            title: 'Error removing location.',
          })
          return
        }

        SuccessToast({
          title: 'Location has been deleted successfully.',
        })

        navigate(`${ROUTE_PROJECTS}/${projectId}`)
        hideConfirmationDialog()
      },
    })
  }
  const handleSuccessEdit = (locationRes: ILocation) => {
    setLocation(locationRes)
    setIsEdit(false)
  }
  const renderRightItems = () => {
    if (canCreate) {
      return (
        <>
          <EdifyButton
            noBackground
            title={isEdit ? 'Cancel' : 'Edit'}
            onClick={handleEditClicked}
            buttonStyle={{ maxHeight: '44px' }}
          />
          {/* <EdifyButton title={'Delete'} onClick={handleDeleteClicked} /> */}
        </>
      )
    }
  }
  if (projectLoading || locationLoading)
    return (
      <PageContainer mainPageLoading={true}>
        <p>loading</p>
      </PageContainer>
    )
  return (
    <PageContainer
      mainPageLoading={locationLoading}
      mainPageError={locationError}
      title={locationName ?? 'Location'}
      
      // subtitle={projectName}
      // renderRightItems={renderRightItems}
      breadCrumbs={getBreadCrumbs()}
      topActions={
        canEdit
          ? [
            {
              title: 'Delete',
              callback: handleDeleteClicked,
            },
          ]
          : []
      }
    >
      {isEdit && projectId ? (
        <LocationForm
          onSuccessEditCB={handleSuccessEdit}
          projectId={projectId}
          initialLocation={location as ILocation}
          handleEditClicked={handleEditClicked}
        />
      ) : (
        <LocationCard location={location as ILocation} handleEditClicked={handleEditClicked} />
      )}
    </PageContainer>
  )
}

const LocationPageWithPermission = withPermission(_Location, ERole.LocationRead)

const Location: React.FC = () => {
  const { projectId } = useParams()
  return (
    <ProjectPageViewModel projectIdProp={projectId}>
      <LocationPageViewModel>
        <LocationPageWithPermission />
      </LocationPageViewModel>
    </ProjectPageViewModel>
  )
}

export default Location
